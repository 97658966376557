import React from 'react'
import { graphql } from 'gatsby'
import './content.scss'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ContentTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  let htmlTitle = frontmatter.title.replace('\n', '<br>')

  return (
    <Layout>
      <SEO lang={frontmatter.lang}
           title={frontmatter.title}
           description={frontmatter.description}
           keywords={frontmatter.keywords}/>
      <section>
        <h1 dangerouslySetInnerHTML={{ __html: htmlTitle }}/>

        <div className="row mt-4">
          <div className="pub-abstract col-md-12 col-lg-8">
            <p>{frontmatter.abstract}</p>
          </div>
          <div className="pub-citation col-md-12 col-lg-4">
            <i className="fa fa-book"/> Cite this article:
            <pre>
              <code>{frontmatter.cite}</code>
            </pre>
          </div>
        </div>

        <hr/>

        <div className="row mt-4">
          <div className="content col-12"
               dangerouslySetInnerHTML={{ __html: html }}/>
        </div>
      </section>
    </Layout>
  )
}

export const contentQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        lang
        path
        title
        description
        keywords
        abstract
        cite
      }
    }
  }
`

export default ContentTemplate
